import React, { useState, useEffect, useRef } from "react";
import { ProjectDetail } from "../";
import { useParams, Link } from "react-router-dom";
import "./Portfolio.css";
import useFetch from "use-http";

import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

const calcImageHeight = (refTop) => {
    if (refTop) {
        console.log("refTop enters " + refTop);
        const height = window.innerHeight;
        const elY = refTop;
        //const elY = el.getBoundingClientRect().y;
        // console.log(elY);
        const newHeight = height - elY - 15 - 21;
        return newHeight;
    } else {
        return -1;
    }
};

const filteredPjList = (pjData, cat) => {
    // funzione che filtra i pj prima secondo categoria e poi li ordina in modo decrescente
    // secondo il valore in "acf.pj-stars", per averli ordinati con un criterio definito in wordpress.
    // occhio, se da errore è perchè ho rimosso IF statement che ritorna "null" qualora non ci fosse risultato
    // per il filtro "includes(cat)"!!!

    let catProjects = pjData.filter((el) => {
        return el.categories.includes(cat);
    });
    catProjects
        .sort((a, b) => {
            return parseInt(a.acf["pj-stars"]) - parseInt(b.acf["pj-stars"]);
        })
        .reverse();
    return catProjects;
};

const Portfolio = (props) => {
    const { get, response } = useFetch();

    //la variabile che contiene l'id del progetto nell'url
    const { id } = useParams();

    const [projectsData, setProjectsData] = useState([]);

    const [stage, setStage] = useState(null);

    const [stageHeight, setStageHeight] = useState("");

    const [preview, setPreview] = useState(null);

    const [isMobile, setIsMobile] = useState(false);

    const resetPreview = () => {
        setPreview(null);
    };

    //esegue una volta, quando la componente si è montata
    useEffect(() => {
        window.innerWidth <= 480 ? setIsMobile(true) : setIsMobile(false);
        fetchData();
    }, []);

    const fetchData = async () => {
        await get("/portfolio?per_page=50");
        if (response.ok) {
            setProjectsData(response.data);
        }
    };

    const stageRef = useRef(null);

    const updatePosition = () => {
        const newHeight = calcImageHeight(stageRef.current.getBoundingClientRect().top);
        setStageHeight(newHeight + "px");
    };

    useEffect(() => {
        window.addEventListener("resize", updatePosition);
        window.innerWidth <= 480 ? setIsMobile(true) : setIsMobile(false);
        return () => window.removeEventListener("resize", updatePosition);
    }, []);

    useEffect(() => {
        if (!(!projectsData || (id && !stage))) {
            updatePosition();
        } else {
            // setTimeout(() => {
            //     updatePosition();
            // }, 300);
            setStageHeight("12px");
        }
    }, [stageRef, projectsData, id, stage]);

    //console.log(stageHeight);

    useEffect(() => {
        //se c'è un progetto e se c'è un id nell'url (quindi è stato cliccato il progetto!)
        if (projectsData.length && id) {
            const data = projectsData.filter((project) => project.id === parseInt(id));
            if (data.length) {
                const cleanUrls = data[0].acf["pj-img"].map((img) => {
                    return img.url;
                });

                setStage({
                    pjSlug: data[0].slug,
                    urls: cleanUrls,
                    imgHeight: stageHeight,
                    project: data[0],
                });
                return;
            }
        }

        setStage(null);

        return;
    }, [projectsData, id]);

    const scroll = (scrollOffset) => {
        stageRef.current.scrollLeft += scrollOffset;
    };

    if (!projectsData || (id && !stage)) {
        return null;
    }

    return (
        <div className="portfolio-container">
            <div className="portfolio-leftSide">
                <p className="t2 mb2">{props.titleLabel}</p>
                {stage && (
                    <Slide right duration={300} when={!!stage} appear>
                        <div className="pj-detail">
                            {/*Se non faccio questo controllo, se mi trovo allo slideshow e torno sul protfolio crasha. (inDetailRaw è nullo... Nonostante i progetti ci siano.)*/}
                            {projectsData.length && <ProjectDetail currentPj={stage.project.acf} resetPreview={resetPreview} />}
                        </div>
                    </Slide>
                )}
                {!stage && (
                    <Slide left duration={300}>
                        <div className="portfolio-pjList">
                            <p className="projects-catLabel">{props.categoryLabels.gd}</p>
                            <ul>
                                {filteredPjList(projectsData, 2).map((element, i) => {
                                    return (
                                        <li key={i}>
                                            <Link
                                                to={`/${element.id}`}
                                                onMouseOver={
                                                    isMobile
                                                        ? () => {
                                                              return false;
                                                          }
                                                        : () => {
                                                              setPreview({ url: element.acf["pj-img"][0].url });
                                                          }
                                                }
                                                onMouseOut={() => {
                                                    setPreview(null);
                                                }}
                                            >
                                                ↘ {element.acf["pj-title"]}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                            <p className="projects-catLabel mt1">{props.categoryLabels.ph}</p>
                            <ul>
                                {filteredPjList(projectsData, 3).map((element, i) => {
                                    return (
                                        <li key={i}>
                                            <Link
                                                to={`/${element.id}`}
                                                onMouseOver={
                                                    isMobile
                                                        ? () => {
                                                              return false;
                                                          }
                                                        : () => {
                                                              setPreview({ url: element.acf["pj-img"][0].url });
                                                          }
                                                }
                                                onMouseOut={() => {
                                                    setPreview(null);
                                                }}
                                            >
                                                ↘ {element.acf["pj-title"]}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Slide>
                )}
            </div>
            <div className="portfolio-stageContainer">
                <div style={{ overflow: "hidden" }}>
                    <div ref={stageRef} className="portfolio-stage" id="PORTFOLIOSTAGE" style={{ height: stageHeight }}>
                        {id
                            ? stage.urls.map((e, i) => {
                                  return (
                                      <img
                                          onClick={(images, index) => {
                                              props.triggerSlideshow(stage.urls, i);
                                          }}
                                          key={i}
                                          src={e}
                                          alt={" "}
                                      />
                                  );
                              })
                            : preview && <img src={preview.url} alt={" "} />}
                    </div>
                </div>
                {id && (
                    <div className="portfolio-stageControls mt0" style={{ top: stageHeight }}>
                        {id && (
                            <div>
                                <span style={{ marginRight: "21px" }} onClick={() => scroll(-250)}>
                                    ←
                                </span>
                                <span onClick={() => scroll(250)}>→</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Portfolio;
