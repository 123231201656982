import React, { useState, useEffect } from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import { Header, Slideshow, Portfolio } from "../components";
import { Helmet } from "react-helmet";
import "../css/Home.css";
import useFetch from "use-http";
import Fade from "react-reveal/Fade";

const Home = () => {
    document.body.style = "background-color: white";

    const { get, response } = useFetch();

    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await get("/pages");
        const currentPage = response.data.filter((page) => page.slug === "home")[0];
        if (response.ok) {
            setPageData(currentPage.acf);
        }
    };

    const [state, setState] = useState({
        slideshow: {
            isOn: false,
            images: null,
            currentIndex: null,
        },
    });

    //handleSlideshowData serve solo a fare arrivare i dati dalla componente.
    //All'interno di home, eseguo l'apertura della componente Slideshow.
    const handleSlideshowData = (images, index) => {
        openSlideshow(images, index);
    };

    const openSlideshow = (images, index) => {
        setState({
            slideshow: {
                isOn: true,
                images: images,
                currentIndex: index,
            },
        });
    };

    const closeSlideshow = () => {
        setState({
            slideshow: {
                isOn: false,
                images: null,
                currentIndex: null,
            },
        });
    };

    if (!pageData) {
        return null;
    }

    if (state.slideshow.isOn) {
        return (
            <Fade duration={500}>
                <Slideshow images={state.slideshow.images} firstImageIndex={state.slideshow.currentIndex} closeSlideshow={closeSlideshow} />
            </Fade>
        );
    } else {
        return (
            <div>
                <Header />
                {/* <p className="t2 mb2">{pageData.home_titolo}</p> */}
                <Portfolio
                    titleLabel={pageData.home_titolo}
                    categoryLabels={{
                        ph: pageData.home_labelcategoriaph,
                        gd: pageData.home_labelcateogiragd,
                    }}
                    triggerSlideshow={handleSlideshowData}
                />
            </div>
        );
    }
};

export default Home;
