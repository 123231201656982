import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => (
    <div>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
            <Link to={"/"}>click here, and may the magic of internet bring you back to a happier place.</Link>
        </p>
    </div>
);

export default NotFoundPage;
