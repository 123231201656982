import React from "react";
import { Link } from "react-router-dom";

const ProjectDetail = ({ currentPj, resetPreview }) => (
    <div>
        <p className="mb0 projects-catLabel">
            <Link to="/" onClick={resetPreview}>
                ← back
            </Link>
        </p>
        <p className="t2 mb3">{currentPj["pj-title"]}</p>
        <p>{currentPj["pj-cliente"]}</p>
        <p>{currentPj["pj-anno"]}</p>
        <p className="mb1">{currentPj["pj-tipo"]}</p>
        <p>{currentPj["pj-descrizione"]}</p>
    </div>
);

export default ProjectDetail;
