import React, { useState } from 'react';
import './Slideshow.css'

const Slideshow = ({ images, firstImageIndex, closeSlideshow }) => {
    document.body.style = 'background-color: black'

    const [state, setState] = useState({
        'imageUrls': [...images],
        'numImages': images.length,
        'currentIndex': firstImageIndex,
    });
    //console.log(state)
    const handleChangeImg = (index, isPositive) => {
        let newIndex;
        if (isPositive) {
            newIndex = (index + 1) % state.numImages;
        } else {
            newIndex = (index === 0) && state.numImages - 1 || index - 1;
        }
        setNewIndex(newIndex)
    }
    const setNewIndex = (newIndex) => {
        console.log("executed!")
        setState({
            'imageUrls': [...images],
            'numImages': images.length,
            'currentIndex': newIndex,
        })
    }

    return (
        <div className="slideshow-container">
            <div className="slideshow-close mb2"><span className="t1 " onClick={closeSlideshow}>close</span></div>
            <div className="slideshow-stage">
                <div className='slideshow-controls'>
                    <div className='slideshow-ctrls-left' onClick={() => { handleChangeImg(state.currentIndex, false) }} />
                    <div className='slideshow-ctrls-right' onClick={() => { handleChangeImg(state.currentIndex, true)}} />
                </div>
                <img className='mb2' src={state.imageUrls[state.currentIndex]} alt=' '></img>
            </div>
            <div className="slideshow-info">
            <p>
                <span style={{ marginRight: '21px' }} onClick={() => { handleChangeImg(state.currentIndex, false) }} >←</span>{state.currentIndex + 1} / {state.numImages }<span style={{ marginLeft: '21px' }} onClick={() => { handleChangeImg(state.currentIndex, true) }} >→</span>
            </p>
            </div>
        </div>
    )
}

export default Slideshow;