// import logo from "./logo.svg";
import About from "./pages/About";
import Home from "./pages/Home";
import NotFoundPage from "./pages/404";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "use-http";
import "./css/reset.css";
import "./css/global.css";

function App() {
    window.localStorage.clear();
    return (
        <Provider url={process.env.REACT_APP_PROVIDER_URL}>
            {/* <Router basename={process.env.PUBLIC_URL}> */}
            <Router basename="/">
                <Switch>
                    <Route path="/about" exact>
                        <About />
                    </Route>
                    <Route path="/:id" exact>
                        <Home />
                    </Route>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="*">
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
        </Provider>
    );
}

export default App;
