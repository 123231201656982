import React, { useState, useEffect } from "react";
import { Header } from "../components";
import { Helmet } from "react-helmet";
import "../css/About.css";
import useFetch from "use-http";
import { useLocation, Link } from "react-router-dom";

function aboutElement(label, content) {
    const formattedContent = content
        .split(`\n\n`)
        .map((paragraph) => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
        .join(``);
    return (
        <div className={"about-box-container mb1"}>
            <div className="about-box-label">{label}</div>
            <div className="about-box-content" dangerouslySetInnerHTML={{ __html: formattedContent }} />
        </div>
    );
}

const About = () => {
    const [aboutData, setAboutData] = useState(null);

    const { get, response } = useFetch();

    const { pathname } = useLocation();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await get("/pages");

        if (!response.ok) {
            return;
        }

        const currentPage = response.data.filter((page) => page.slug === "about")[0];

        if (currentPage) {
            setAboutData(currentPage.acf);
        }
    };

    if (!aboutData) {
        return null;
    }

    return (
        <div>
            <Header />

            <p className="t2 mb2">{aboutData.about_titolo}</p>
            <div className={"about-content"}>
                <div className="about-curriculum">
                    {aboutElement(aboutData.about_education_label, aboutData.about_education)}
                    {aboutElement(aboutData.about_workexperience_label, aboutData.about_workexperience)}
                    {aboutElement(aboutData.about_exhibitions_label, aboutData.about_exhibitions)}
                </div>
                <div className="about-credits">
                    <div className="mb1" dangerouslySetInnerHTML={{ __html: aboutData.about_contact }} />
                    <div dangerouslySetInnerHTML={{ __html: aboutData.about_credits }} />
                </div>
            </div>
        </div>
    );
};

export default About;
