import React, { useState, useEffect } from "react";
// import { Link, graphql, useStaticQuery } from 'gatsby';
import "./Header.css";
import useFetch from "use-http";
import { useLocation, Link, useParams } from "react-router-dom";

const Header = (props) => {
    const [headerData, setHeaderData] = useState(null);

    const { get, response } = useFetch();

    const { pathname } = useLocation();

    const { id } = useParams();

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        await get("/pages");

        if (!response.ok) {
            return;
        }

        const currentPage = response.data.filter((page) => page.slug === "_header")[0];

        if (currentPage) {
            setHeaderData(currentPage.acf);
        }
    };

    if (!headerData) {
        return null;
    }
    // console.log(pathname);
    return (
        <div className="header mb4">
            <h1>
                {headerData.header_titolo}
                <br></br>
                {pathname === "/" && (
                    <span>
                        <Link to="/about"> {headerData.header_inhome} </Link>
                    </span>
                )}
                {pathname === "/about" && (
                    <span>
                        <Link to="/"> {headerData.header_inabout} </Link>
                    </span>
                )}
                {pathname === `/${id}` && <Link to="/about"> {headerData.header_inhome} </Link>}
            </h1>
        </div>
    );
};

export default Header;
